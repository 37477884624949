import React from "react"

const SvgRedis = props => (
  <svg viewBox="0 0 1000 1000" {...props}>
    <path d="M570.6 766c-57.8 30.1-89.7 29.8-135.4 8C389.8 752.2 10 593.5 10 593.5v115c0 9.9 13.8 20.3 39.6 32.7 51.9 24.9 340.2 141.1 385.5 163 45.4 21.8 77.5 21.9 135.4-8 57.8-30.1 328.4-141.2 380.7-168.7 26.6-13.8 38.3-24.7 38.3-34.4V579.5h-.1c-.1.1-361.3 156.3-418.8 186.5z" />
    <path d="M570.6 604.4c-57.8 30.1-89.7 29.8-135.4 8C389.8 590.5 10 431.7 10 431.7v115c0 9.9 13.8 20.3 39.6 32.7 51.9 25 340.2 141.2 385.5 163.2 45.4 21.8 77.5 21.9 135.4-8 57.8-30.1 328.4-141.2 380.7-168.7 26.6-13.8 38.3-24.7 38.3-34.4V417.9h-.1c-.1.1-361.3 156.3-418.8 186.5z" />
    <path d="M989.6 266.6c.5-9.9-12.7-18.7-38.8-28.3-51-18.7-320.1-125.8-371.7-144.7-51.7-18.9-72.6-18-133.2 3.6-60.4 21.5-347.1 134.1-398 154.1-25.7 10-38.2 19.5-37.5 29.5v-.1 113.2s379.5 159.9 425.2 181.7c45.4 21.8 77.5 21.9 135.4-8 57.6-30.4 419-189.2 419-189.2l-.4-111.8zm-126.8 3L714 328.3l-134.1-53 148.6-58.8 134.3 53.1zM588 325.4L519.3 426 361 360.3l227-34.9zM468.8 172.5L446.9 132l68.5 26.7 64.4-21.2-17.5 41.8L628 204l-84.9 8.7-19.1 45.9-30.6-51.1-98-8.7 73.4-26.3zm-169.1 57c67 0 121.4 20.9 121.4 47 0 25.8-54.4 47-121.4 47s-121.4-20.9-121.4-47c.1-25.7 54.4-47 121.4-47z" />
  </svg>
)

export default SvgRedis
