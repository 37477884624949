import React from "react"
import Section from "./section"
import style from "../css/clients.module.scss"

import aeins from "../images/companies/a1.png"
import babywalz from "../images/companies/baby-walz.svg"
import bwin from "../images/companies/bwin.svg"
import nidecker from "../images/companies/nidecker.svg"
import nitro from "../images/companies/nitro.svg"
import sua from "../images/companies/sua.svg"
import timezone from "../images/companies/timezone.svg"
import toddgo from "../images/companies/toddgo.svg"
import wwp from "../images/companies/wwp.svg"
import ps from "../images/companies/planet-sports.svg"
import k5 from "../images/companies/K5.svg"
import porsche from "../images/companies/porsche-motorsport.svg"
import pinops from "../images/companies/pinops.svg"
import cc from "../images/companies/cc.svg"
import intersport from "../images/companies/intersport.svg"
import boss from "../images/companies/boss.svg"
import sunrise from "../images/companies/sunrise.svg"

const Clients = () => (
  <Section className={style.clients}>
    <h2>You're in Good Company</h2>
    <ul>
      <li className={style.clientA1}><a href="https://a1.net" target="_blank" rel="noopener noreferrer"><img src={aeins} alt="A1"/></a></li>
      <li className={style.clientBabywalz}><a href="https://www.baby-walz.de" target="_blank" rel="noopener noreferrer"><img src={babywalz} alt="babywalz"/></a></li>
      <li className={style.clientBoss}><a href="https://www.boss.com" target="_blank" rel="noopener noreferrer"><img src={boss} alt="HUGO BOSS"/></a></li>
      <li className={style.clientBwin}><a href="https://www.bwin.com" target="_blank" rel="noopener noreferrer"><img src={bwin} alt="bwin"/></a></li>
      <li className={style.clientCC}><a href="https://www.calligraphy-cut.com" target="_blank" rel="noopener noreferrer"><img src={cc} alt="calligraphy cut"/></a></li>
      <li className={style.clientIntersport}><a href="https://www.intersport.com" target="_blank" rel="noopener noreferrer"><img src={intersport} alt="Intersport"/></a></li>
      <li className={style.clientK5}><a href="https://www.k5.de" target="_blank" rel="noopener noreferrer"><img src={k5} alt="K5"/></a></li>
      <li className={style.clientNidecker}><a href="https://www.nidecker.com" target="_blank" rel="noopener noreferrer"><img src={nidecker} alt="Nidecker"/></a></li>
      <li className={style.clientNitrousa}><a href="https://www.nitrousa.com" target="_blank" rel="noopener noreferrer"><img src={nitro} alt="Nitro Snowboards"/></a></li>
      <li className={style.clientPinops}><a href="https://www.pinops.com" target="_blank" rel="noopener noreferrer"><img src={pinops} alt="pinops"/></a></li>
      <li className={style.clientPlanetsports}><a href="https://www.planet-sports.com" target="_blank" rel="noopener noreferrer"><img src={ps} alt="Planet Sports"/></a></li>
      <li className={style.clientPorsche}><a href="https://motorsports.porsche.com" target="_blank" rel="noopener noreferrer"><img src={porsche} alt="Porsche Motorsport"/></a></li>
      <li className={style.clientSua}><a href="https://shop-usability-award.de" target="_blank" rel="noopener noreferrer"><img src={sua} alt="Shop Usability Award"/></a></li>
      <li className={style.clientSunrise}><a href="https://www.sunrise.ch" target="_blank" rel="noopener noreferrer"><img src={sunrise} alt="Sunrise"/></a></li>
      <li className={style.clientTimezone}><a href="https://www.timezone.de" target="_blank" rel="noopener noreferrer"><img src={timezone} alt="Timezone"/></a></li>
      <li className={style.clientToddgo}><a href="https://www.toddgo.com" target="_blank" rel="noopener noreferrer"><img src={toddgo} alt="toddgo"/></a></li>
      <li className={style.clientWwp}><a href="https://www.wwp-group.com" target="_blank" rel="noopener noreferrer"><img src={wwp} alt="WWP Group"/></a></li>
    </ul>
  </Section>
)

export default Clients
