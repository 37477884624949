import React from "react"
import Section from "./section"
import style from "../css/mantra.module.scss"

const Mantra = () => (
  <Section className={style.mantra}>
    <h2>Building Your Digital Dreams</h2>
    <p>
      At oakay, we don't just write code; we craft tailored software solutions that put your users
      and stakeholders first. Armed with battle-tested open-source tools and frameworks, we're on a
      mission to deliver top-tier web & mobile applications. Our architecture? It's as flexible
      as your imagination – loosely coupled and ready for continuous expansion.
    </p>
  </Section>
)

export default Mantra
