import React from "react"

import SEO from "../components/seo"

import Header from "../components/header"
import Intro from "../components/intro"
import Mantra from "../components/mantra"
import Clients from "../components/clients"
import Contact from "../components/contact"

import "../css/application.scss"

const IndexPage = () => (
  <>
  <SEO keywords={[`oakay`, `software`, `development`, `ruby on rails`, `react`, `reactjs`]} />
  <Header/>
  <article>
    <Intro/>
    <Mantra/>
    <Clients/>
    <Contact/>
  </article>
  </>
)

export default IndexPage
