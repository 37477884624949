import React, { Component } from "react"
import Section from "./section"
import Stack from "./stack"
import style from "../css/intro.module.scss"
// import lottie_light from 'lottie-web'
// import * as animationData from '../images/rocket.json'

class Intro extends Component {
  componentDidMount() {
    // let params = {
    //   container: document.getElementsByClassName(style.rocket)[0],
    //   renderer: 'svg',
    //   loop: true,
    //   autoplay: true,
    //   animationData: animationData.default
    // }

    // lottie_light.loadAnimation(params)
  }

  render() {
    return (
      <Section className={style.intro}>
        <h3 className={style.tldr}>We design, build, operate and sometimes<br/> rescue web & mobile applications.</h3>
        <h1 className={style.inyourface}>
          Hey, we are oakay,<br/> a <u>software development</u><br/> company.
        </h1>

        {/* <div className={style.rocket}></div> */}

        <Stack/>
      </Section>
    );
  }
}

export default Intro
