import React from "react"
import Section from "./section"
import style from "../css/contact.module.scss"

const Contact = () => (
  <Section className={style.contact}>
    <div className="row">
      <div className="col-12 col-6-m">
        <h2>Imprint</h2>
        <p>oakay GmbH<br/>Landstrasse 38, 9490 Vaduz<br/>Liechtenstein</p>
        <p>Register number: FL-0002.579.612-2<br/>Commercial register, Vaduz</p>
      </div>
      <div className="col-12 col-6-m">
        <h2>Let's Chat</h2>
        <p>
          <span itemProp="telephone"><a href="tel:+4237987987">+423 798 798 7</a></span>
          <span itemProp="email"><a href="mailto:hello@oakay.com">hello@oakay.com</a></span>
        </p>
      </div>
    </div>
    <div className={style.privacyPolicy}>
      This website doesn't store any of your data, period. External links are selected and reviewed when the page is published. However, we are not responsible for the content of external websites.
    </div>
   </Section>
)

export default Contact
