import React from "react"
import PropTypes from "prop-types"

const Section = ({ className, children }) => (
  <section className={className}>
    <div className="container">
      {children}
    </div>
  </section>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node.isRequired,
}

export default Section
