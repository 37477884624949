import React from "react"
import SvgRails from "../images/stack/Rails"
import Elastic from "../images/stack/Elastic"
import Redis from "../images/stack/Redis"
import Mysql from "../images/stack/Mysql"
import Docker from "../images/stack/Docker"
import SvgVuejs from "../images/stack/Vuejs"
import style from "../css/stack.module.scss"

const Stack = () => (
  <div className={style.stack}>
    <h2>Tools of the trade</h2>
    <ul className={style.logos}>
      <li><a href="http://rubyonrails.org/" target="_blank" rel="noopener noreferrer" title="Ruby on Rails"><SvgRails className={style.rails}/></a></li>
      <li><a href="https://www.elastic.co/products/elasticsearch" target="_blank" rel="noopener noreferrer" title="Elastic"><Elastic className={style.elastic}/></a></li>
      <li><a href="https://redis.io/" target="_blank" rel="noopener noreferrer" title="Redis"><Redis className={style.redis}/></a></li>
      <li><a href="https://www.mysql.com/" target="_blank" rel="noopener noreferrer" title="MySQL"><Mysql className={style.mysql}/></a></li>
      <li><a href="https://www.docker.com/" target="_blank" rel="noopener noreferrer" title="Docker"><Docker className={style.docker}/></a></li>
      <li><a href="https://vuejs.org/" target="_blank" rel="noopener noreferrer" title="Vuejs"><SvgVuejs className={style.vue}/></a></li>
    </ul>
    <ul className={style.list}>
      <li><a href="https://openai.com" target="_blank" rel="noopener noreferrer">OpenAI</a>,</li>
      <li><a href="https://github.com/" target="_blank" rel="noopener noreferrer">GitHub</a>,</li>
      <li><a href="https://cloud.google.com/" target="_blank" rel="noopener noreferrer">Google Cloud Platform</a>,</li>
      <li><a href="https://aws.amazon.com/" target="_blank" rel="noopener noreferrer">Amazon Web Services</a></li>
    </ul>
  </div>
)

export default Stack
